<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="trials"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        :locale="$i18n.locale"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-event="openCaseDetail"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden"></div>

            <!-- Current Month -->
            <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
            >
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">
                  {{ showDate | monthAndYear($i18n.locale) }}
                </span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  :key="
                    String(view.val) +
                    (calendarView === view.val ? 'filled' : 'border')
                  "
                  :type="calendarView === view.val ? 'filled' : 'border'"
                  class="p-3 md:px-8 md:py-3"
                  :class="{
                    'border-l-0 rounded-l-none': index,
                    'rounded-r-none': calendarViewTypes.length !== index + 1,
                  }"
                  @click="calendarView = view.val"
                  >{{ $t(`calendar.types.${view.label}`) }}</vs-button
                >
              </template>
            </div>
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- EDIT EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      :title="`${selectedCase.case.cid} ${selectedCase.case.customer.alias}`"
      :accept-text="$t('calendar.buttons.goToCase')"
      :cancel-text="$t('calendar.buttons.close')"
      button-cancel="border"
      @close="cancelDetail"
      @cancel="cancelDetail"
      @accept="goToCase"
      :active="true"
      v-if="!!selectedCase"
    >
      <div class="my-4">
        <small class="date-label">{{ $t("calendar.titles.day") }}</small>
        <div>
          {{
            $moment(selectedCase.case[selectedCase.key].toDate()).format(
              "DD/MM/YYYY"
            )
          }}
        </div>
      </div>
      <div class="my-4">
        <small class="date-label">{{ $t("calendar.titles.hour") }}</small>
        <div>
          {{
            $moment(selectedCase.case[selectedCase.key].toDate()).format(
              "HH:mm"
            )
          }}
        </div>
      </div>
      <template v-for="x in ['otherPart', 'otherSolicitor', 'otherLawyer']">
        <div class="my-4" v-if="!!selectedCase.case[x]" :key="`data_${x}`">
          <small class="date-label">{{ $t(`civilCases.fields.${x}`) }}</small>
          <div>{{ selectedCase.case[x] }}</div>
        </div>
      </template>
    </vs-prompt>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");

import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  filters: {
    monthAndYear: (val, locale) => {
      return moment(val).locale(locale).format("MMM YYYY");
    },
  },

  data() {
    return {
      showDate: new Date(),
      disabledFrom: false,
      selectedCase: null,

      calendarView: "month",

      calendarViewTypes: [
        {
          label: "month",
          val: "month",
        },
        {
          label: "week",
          val: "week",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["allCivilCases"]),
    isLawyer() {
      return this.$store.state.AppActiveUser.role === "lawyer";
    },
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    trials() {
      let items = [
        { key: "demandResponseDueDate", color: "yellow" },
        { key: "priorHearingDate", color: "orange" },
        { key: "trialHearingDate", color: "red" },
      ];

      const filterItemCases = (item) => {
        const filterByDatesCriteria = (c) =>
          c[item.key] !== null && c[item.key] !== undefined;
        let filterCriteria = filterByDatesCriteria;
        if (this.isLawyer) {
          //----- SI SOY LAWYER, FILTRO POR MIS CLIENTES! ------
          let lawyerCustomers = this.$store.state.AppActiveUser.customers;
          if (lawyerCustomers) {
            filterCriteria = (c) => {
              return (
                filterByDatesCriteria(c) &&
                lawyerCustomers.includes(c.customer.id)
              );
            };
          }
          //----- SI SOY LAWYER, FILTRO POR MIS ESTABLECIMIENTOS! ------
        }
        return this.allCivilCases.filter(filterCriteria).map((c) => {
          let style = `background-color: ${item.color} !important`;
          if (item.color === "yellow") style += "; color: black !important;";
          return {
            id: `${item.key}_${c.id}`,
            startDate: c[item.key].toDate(),
            endDate: c[item.key].toDate(),
            title: `${this.$moment(c[item.key].toDate()).format("HH:mm")} - ${
              c.cid
            } ${c.customer.alias}`,
            classes: "event-primary",
            style,
            key: item.key,
            case: c,
          };
        });
      };

      return [].concat(...items.map(filterItemCases));
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    async openCaseDetail(event) {
      this.selectedCase = this.trials.find((c) => c.id === event.id);
    },
    goToCase() {
      this.$router.push(`/civil/civilCases/${this.selectedCase.case.id}`);
      this.selectedCase = null;
    },
    cancelDetail() {
      this.selectedCase = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";

.custom-calendar-loading {
  position: relative;
  width: 100%;
  height: 110px;

  .loading {
    top: calc(50% - 35px);
  }
}
</style>
